import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createSection, deleteSection, getAllSections, getOneSection, updateSection, } from './actions';
var initialState = {
    sections: { docs: [], meta: {} },
    section: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'sections',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // CREATE ONE
        builder
            .addCase(createSection.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createSection.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.sections = action.payload.data;
        })
            .addCase(createSection.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updateSection.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateSection.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.sections = action.payload.data;
        })
            .addCase(updateSection.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getAllSections.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllSections.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.sections = action.payload;
        })
            .addCase(getAllSections.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneSection.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneSection.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.section = action.payload.data;
        })
            .addCase(getOneSection.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteSection.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteSection.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.sections.docs = state.sections.docs.filter(function (section) { return section._id !== action.meta.arg; });
        })
            .addCase(deleteSection.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
